var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('r-app',{attrs:{"sidebar-menu-data":{
      menus: _vm.menus,
      defaultIcon: 'brightness-1',
      logoUrl: '/images/logo-rise.png',
      settings: {
        showedMenus: {
          firstLevel: 5,
          secondLevel: 2,
        },
      },
    }},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('r-profile',{attrs:{"userData":{
          name: _vm.userCurrent.name,
          role: _vm.formatRole(_vm.userCurrent.role),
          profilePicture: _vm.profilePicture,
        }}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","tag":"router-link"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])],1)]},proxy:true},{key:"default",fn:function(){return [_c('router-view')]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_vm._v("Roketin Payment v"+_vm._s(_vm.APP_VERSION))])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }