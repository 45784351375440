<template>
  <div>
    <r-app
      :sidebar-menu-data="{
        menus: menus,
        defaultIcon: 'brightness-1',
        logoUrl: '/images/logo-rise.png',
        settings: {
          showedMenus: {
            firstLevel: 5,
            secondLevel: 2,
          },
        },
      }"
    >
      <template #header-right>
        <r-profile
          :userData="{
            name: userCurrent.name,
            role: formatRole(userCurrent.role),
            profilePicture: profilePicture,
          }"
        >
          <b-dropdown-item
            aria-role="listitem"
            tag="router-link"
            @click="logout()"
          >
            Logout
          </b-dropdown-item>
        </r-profile>
      </template>
      <template #default>
        <router-view />
      </template>
      <template #footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">Roketin Payment v{{ APP_VERSION }}</div>
        </div>
      </template>
    </r-app>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  async mounted() {
    await this.$store.dispatch('auth/getCurrentUser')
    await this.loadCurrentUser()
  },
  computed: {
    menus() {
      if (this.currentUser?.user?.role == 'SUPER_ADMIN') {
        return [
          {
            basePath: '/admin-payment',
            title: 'Admin Payment',
            icon: 'cash-multiple',
            hideSecondLevelMenu: true,
            children: [
              {
                path: '/',
                title: 'History Payment',
              },
              {
                path: '/refund',
                title: 'Refund',
              },
            ],
          },
          {
            basePath: '/admin-management',
            title: 'Admin Payment Management',
            icon: 'account-supervisor',
          },
        ]
      } else {
        return [
          {
            basePath: '/admin-payment',
            title: 'Admin Payment',
            icon: 'cash-multiple',
            hideSecondLevelMenu: true,
            children: [
              {
                path: '/',
                title: 'History Payment',
              },
              {
                path: '/refund',
                title: 'Refund',
              },
            ],
          },
        ]
      }
    },
    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      APP_VERSION: process.env.VUE_APP_VERSION
        ? process.env.VUE_APP_VERSION
        : '-',
      isNotificationActive: false,
      isOnline: false,
      isLoadUser: false,
      notificationData: [
        {
          message: 'Ricky Septian asks to change his profile picture',
          date: 'Aug 19, 2020',
          time: '08:18',
        },
        {
          message: 'Ricky Septian asks to change his profile picture',
          date: 'Aug 19, 2020',
          time: '08:18',
        },
      ],
      userCurrent: {
        email: '',
        name: '',
        id: null,
        role: '',
      },
      profilePicture: require('@/assets/images/avatars.svg'),
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'auth/getCurrentUser',
    }),
    logout() {
      this.$store.dispatch('auth/logout')
    },
    formatRole(role) {
      if (role === 'SUPER_ADMIN') {
        return 'Super Admin'
      } else if (role === 'ADMIN') {
        return 'Admin'
      }
    },
    async loadCurrentUser() {
      this.isLoadUser = true
      try {
        let response = await this.fetchCurrentUser()
        this.userCurrent = {
          name: response.data.data.name,
          email: response.data.data.email,
          id: response.data.data.id,
          role: response.data.data.role,
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
